import * as React from 'react'
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from "../components/Layout"
import PageWrapper from '../components/PageWrapper'
// import Seo from "../components/Seo"
import Seo2 from '../components/Seo2'
import isTodayOrBefore from '../utils/isTodayOrBefore';
import TableWrapper, { TableRow } from "../components/Table"
import Button from '../components/Button'
import sanitizeReleaseName from '../utils/sanitizeRecordTitle'
import RecordsFeed from '../components/RecordsFeed'
import { SectionTitle } from '../components/PageTitles'

const RecordTemplate = ( {data} ) => {

    const imageData = getImage(data.wpRelease.featuredImage?.node?.localFile)

    const recordArtistName = data.wpRelease.ltaRecordsSettings.recordArtistSelection.title;

    const getRecordReleaseYear = new Date(data.wpRelease.date);
    const getRecordReleaseDate = new Date(data.wpRelease.ltaRecordsSettings.releaseDate) || getRecordReleaseYear;

    const { recordPreorderLink, recordPurchaseLink, recordStreamLink, lpNumber } = data.wpRelease.ltaRecordsSettings;

    const recordTitle = sanitizeReleaseName(data.wpRelease.title);

    // const metaTitle = `${recordTitle} by ${recordArtistName}`;

    const { edges: otherReleases } = data.allWpRelease

    const recordScroll = [
        {
            title: recordTitle
        },
        {
            title: recordArtistName
        },
        {
            title: recordTitle
        },
        {
            title: recordArtistName
        },
        {
            title: recordTitle
        },
        {
            title: recordArtistName
        }
    ]

    let sortedArr
    if (data.wpRelease?.imprints?.nodes.length > 0) {
        const imprintArray = data.wpRelease.imprints.nodes
        sortedArr = imprintArray.reduce((acc, element) => {
            if (element.name.includes('LTA')) {
              return [element, ...acc];
            }
            return [...acc, element];
          }, []);
          
    }

    return (
        <Layout>
        {/* <Seo title={metaTitle} description={data.wpRelease.seo.metaDesc} image={data.wpRelease.featuredImage?.node?.localFile?.publicURL}>
          <script type="application/ld+json">
                {`{
                  "@context": "https://schema.org",
                  "@type": "MusicRelease",
                  "@id": "https://lighterair.com/records/${getRecordReleaseYear.getFullYear()}/${data.wpRelease.slug}",
                  "headline": "${metaTitle}",
                  "description": "${data.wpRelease.seo.metaDesc ? data.wpRelease.seo.metaDesc : ''}",
                  "image": {
                    "@type": "ImageObject",
                    "url": "https://lighterair.com${data.wpRelease.featuredImage?.node?.localFile?.publicURL}"
                  }
                }`}
              </script>
        </Seo> */}

           <PageWrapper scrollData={recordScroll}
           >
               <h1 className={`lg:hidden sm:text-[5rem] md:text-[6rem] text-[3.25rem] tracking-tight italic leading-none uppercase font-primary -mb-2`}>{recordTitle}</h1>
                    <div className={`flex flex-row justify-start items-start lg:items-center lg:flex-nowrap flex-wrap lg:-m-5 lg:!-mb-5 lg:!-mt-5`}>
                    <figure className={`m-0 lg:p-5 flex-true w-full`}>
                    <GatsbyImage 
                    image={imageData}
                    layout={`fullWidth`}
                    className={`w-full border border-color-text`}
                    alt={data.wpRelease.featuredImage.node.altText || recordTitle}
                    />
                    </figure>
                    <div className={`w-full max-w-full lg:flex-1/2 lg:max-w-1/2 xl:flex-[55%] xl:max-w-[55%] lg:p-5 pt-5`}>
                    <h1 className={`hidden lg:block text-[5rem] italic text-center leading-none uppercase font-primary xl:max-w-[70%] xl:mx-auto`}>{recordTitle}</h1>
                      <Button.Back
                      onClick={() => navigate(-1)}
                      className={`lg:absolute lg:top-3 xl:top-6 lg:left-0 lg:!inline-flex !hidden`}>
                        Back
                      </Button.Back>

                    <TableWrapper className={`xl:px-16 lg:px-10 lg:py-5`}>
                    <TableRow 
                        header={`Artist`}
                        content={recordArtistName}
                    />
                    <TableRow 
                        header={`Title`}
                        content={recordTitle}
                    />
                    
                    {getRecordReleaseDate &&
                    
                        
                    <TableRow 
                    header={isTodayOrBefore(getRecordReleaseDate) === true ? 'Release Date' : 'Coming'}
                    content={data.wpRelease.ltaRecordsSettings.releaseDate}
                    />
                    }

                    {data.wpRelease.releaseFormats.nodes.length > 0 &&
                    
                    <TableRow 
                    header={`Format`}
                    content={
                        data.wpRelease.releaseFormats.nodes.map((form, index) => {
                            return (index ? ', ' : '') + form.name
                        })
                    }
                    />
                    }

                    {data.wpRelease.imprints.nodes.length > 0 &&
                    
                    <TableRow 
                    header={`Imprint`}
                    content={
                      sortedArr.map((imprint, index) => {
                            return (index ? ', ' : '') + imprint.name
                        })
                    }
                    />
                    }

                    {lpNumber &&
                    
                        
                    <TableRow 
                    header={`Release No.`}
                    content={lpNumber}
                    />
                    }
                </TableWrapper>

                <div className="pt-7">

                {
                    isTodayOrBefore(getRecordReleaseDate) === true ? (
                    
                    <div className={`space-x-3 md:space-x-4 text-center`}>
                                    
                                    
                        { recordPurchaseLink && 
                            <Button type={`external`} href={recordPurchaseLink} title={`Purchase ${recordTitle}`}>Buy</Button>
                            }
                            { recordStreamLink && 
                            <Button variation={`secondary`} type={`external`} href={recordStreamLink} title={`Stream ${recordTitle}`}>Stream</Button>
                            }

                    </div>
                    
                    ) : recordPreorderLink ? (
                        <div className={`space-x-2 md:space-x-4 text-center`}>
                                {recordPreorderLink &&
                                    <div>
                                        <Button type={`external`} href={recordPreorderLink} title={`Preorder/Presave ${recordTitle}`}>Preorder</Button>
                                    </div>
                                }
                        </div>
                    ) : (
                        ''
                    )
                }
                </div>
                    </div>
                    </div>

                    {otherReleases.length > 0 ? (

                    <div className={`pt-10`}>
                      <div className={`lg:flex lg:flex-row w-full lg:justify-between lg:items-center`}>
                        <SectionTitle as={`h3`} className={`shrink-0 lg:pr-10 lg:flex-auto lg:text-right xl:pr-14 ${otherReleases.length === 2 ? 'lg:max-w-[66%]' : 'lg:max-w-[33%]'}`}>Other <span className={`lg:block lg:text-8xl lg:font-secondary lg:-mb-2 lg:mt-2 lg:tracking-wider`}>LTA</span> Releases</SectionTitle>
                        <div className={`lg:flex-true md:pt-0 pt-3`}><RecordsFeed recordData={otherReleases} className={`grid-cols-2 md:!grid-cols-2 gap-3 gap-y-3 lg:flex-true ${otherReleases.length === 2 ? 'lg:!grid-cols-2' : otherReleases.length === 3 ? 'lg:!grid-cols-3' : 'lg:!grid-cols-4'}`} style={{flex: 'auto'}} /></div>
                      </div>
                    </div>

                    ) : ( null ) }
           </PageWrapper>
   </Layout>

    )
}

export const Head = ( { data } ) => {

  const metaTitle = `${sanitizeReleaseName(data.wpRelease.title)} by ${data.wpRelease.ltaRecordsSettings.recordArtistSelection.title}`;

  return (
    <Seo2 
    title={metaTitle} 
    description={data.wpRelease.seo.metaDesc} 
    image={data.wpRelease.featuredImage?.node?.localFile?.publicURL} >
  
          <script type="application/ld+json">
          {`{
                  "@context": "https://schema.org",
                  "@type": "MusicRelease",
                  "@id": "https://lighterair.com/records/${new Date(data.wpRelease.date).getFullYear()}/${data.wpRelease.slug}",
                  "headline": "${metaTitle}",
                  "description": "${data.wpRelease.seo.metaDesc ? data.wpRelease.seo.metaDesc : ''}",
                  "image": {
                    "@type": "ImageObject",
                    "url": "https://lighterair.com${data.wpRelease.featuredImage?.node?.localFile?.publicURL}"
                  }
                }`}
          </script>
    </Seo2>
  )
}

export default RecordTemplate 

export const query = graphql`
  query($id: String) {
    wpRelease(id: { eq: $id }) {
      title
      id 
      slug
      date
      seo {
          metaDesc
      }
      ltaRecordsSettings {
        recordArtistSelection {
          ... on WpArtist {
            id
            title
            slug
          }
        }
        recordPreorderLink
        recordPurchaseLink
        recordStreamLink
        releaseDate
        lpNumber
      }
      imprints {
          nodes {
              name
              id
              slug
          }
      }
      releaseFormats {
        nodes {
          name
        }
      }
      terms {
        nodes {
          ... on WpImprint {
            id
            name
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                  quality: 82
              )
            }
            publicURL
          }
        }
      }
    }
    allWpRelease(
      filter: {id: {ne: $id}}
      limit: 4
      sort: {fields: date, order: DESC}
    )  {
      edges {
        node {
          id
          title
          date
          slug
          releaseFormats {
            nodes {
              id
              slug
              name
            }
          }
          ltaRecordsSettings {
            lpNumber
            recordArtistSelection {
              ... on WpArtist {
                id
                title
                slug
              }
            }
            recordPreorderLink
            recordPurchaseLink
            recordStreamLink
            releaseDate
          }
          imprints {
            nodes {
              id
              name
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`